// extracted by mini-css-extract-plugin
export var container = "login-module--container--eCDp-";
export var albin = "login-module--albin--D+lsb";
export var wobble = "login-module--wobble--Z6ImI";
export var note = "login-module--note--zGbPw";
export var myOrbit = "login-module--myOrbit--tr03j";
export var note2 = "login-module--note2--eMJpR";
export var note3 = "login-module--note3--1j1jr";
export var note4 = "login-module--note4--w1hg1";
export var containerWrapper = "login-module--containerWrapper--3dxW4";
export var github = "login-module--github--yEMsd";